<template>
  <div style="font-size: 14pt; text-align: justify">
    <p class="mb-3" style="font-size: 20pt; font-weight: bold; text-align: left">
      UPEI Chem 1120 Final Exam
    </p>

    <p class="mb-3">
      As you have likely been told by your professor, your Chem 1120 exam will be administered here
      on Stemble. The exam opened at 6:00 pm ADT on Friday, April 17, 2020. The exam will end (i.e.
      no more answers may be submitted for grading) at 11:00 pm ADT on Saturday, April 18, 2020.
    </p>

    <p class="mb-3">
      Much like a typical Stemble assignment, you will be given an infinite number of "attempts" to
      get the right answer much like you can try a question as much as you'd like during a final
      exam within the time limits. Unlike a typical Stemble assignment, however, you will not be
      given feedback after each attempt and you will not be shown your grade.
    </p>

    <p class="mb-3">
      There are a total of 30 tasks on this final exam. The first is this page of instructions while
      the second is an Academic Integrity Agreement which you must agree to in order for your exam
      to be graded. The final 28 tasks are the actual exam questions that you must complete.
    </p>

    <p class="mb-0">
      Remember that when inputting numerical values in scientific notation, use an "e" or "E" to
      signify
      <stemble-latex content="$\times 10^{x}$" />
      , where
      <stemble-latex content="$x$" />
      is whatever you input after the "e" or "E".
    </p>
    <p class="ml-5">
      Examples:<br />
      <span class="text-bold">1.23e-4</span>
      <stemble-latex content="$\,\,\rightarrow 1.23 \times 10^{-4}$" />
      <br />
      <span class="text-bold">0.3e4</span>
      <stemble-latex content="$\,\,\rightarrow 0.3 \times 10^{4}$" />
    </p>

    <p class="mb-3">
      Should you have any questions during the examination period, please do not hesitate to contact
      one of us at
      <a class="text-bold" href="mailto:support@stemble.com">support@stemble.com</a>. We will
      respond within one (1) hour of any email. You can also feel free to email your professor, but
      understand that they may not answer between 10:00 pm ADT on Friday and 9:00 am ADT on
      Saturday.
    </p>

    <p class="mb-5" style="font-size: 16pt; font-weight: bold; text-align: left">
      Good luck and enjoy your summer!
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ExamInstructions',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: true,
      },
    };
  },
  created() {
    setTimeout(() => {
      if (this.responses === null || this.responses.length === 0) {
        this.submitResponse();
      }
    }, 5000);
  },
};
</script>
